import { FreeStarAdsAppView } from '@smithsonian/js/ads/freestar/views/AdsAppView';

export const PhotoContestAdsAppView = FreeStarAdsAppView.extend({
    /*
     * Photo Contest Ads App View
     */

    /* photo contest configs */
    settings: [
        {
            name: 'defaultPhotoContestStream',
            placementName: 'smithsonianmag_article_incontent_dynamic',
        },
        {
            name: 'defaultPhotoContestTop',
            placementName: 'smithsonianmag_article_incontent_dynamic',
        },
        {
            name: 'defaultPhotoContestBottom',
            placementName: 'smithsonianmag_article_incontent_dynamic',
        },
        {
            name: 'defaultPhotoContestHeader',
            placementName: 'smithsonianmag_article_incontent_dynamic',
        },
        {
            name: 'defaultPhotoContestMiddle',
            placementName: 'smithsonianmag_article_incontent_dynamic',
        },
        {
            name: 'mobilePhotoContestHeader',
            placementName: 'smithsonianmag_article_incontent_dynamic',
        },
        {
            name: 'mobilePhotoContestStickyBottom',
            placementName: 'smithsonianmag_article_incontent_dynamic',
        },
    ],

    paginate: function (page) {
        // trigger ads refresh
        // check if pubads method exists
        // as it does not exist on the initial site load.
        var node = document.getElementById(
            'div-gpt-ad-chuckweasley-photocontest-stream-' + page
        );
        console.log('div-gpt-ad-chuckweasley-photocontest-stream-' + page);
        if (page && node) {
            freestar.config.enabled_slots.push({
                placementName: 'smithsonianmag_photocontest_new',
                slotId: 'div-gpt-ad-chuckweasley-photocontest-stream-' + page,
            });
        }
    },
});
